p {
  font-size: 1rem;
  line-height: 1.4;
}
small,
.small {
  font-size: 1.5rem;
  line-height: 1.9rem;
  font-weight: bold;
}

h5,
.h5,
h6,
.h6 {
  font-weight: bold;
}

// Type display classes
.display-1 {
  line-height: 4rem;
}
.display-2 {
  line-height: 1.9rem;
}
.display-3 {
  line-height: 1.8rem;
}
.display-4 {
  line-height: 1.3rem;
}
// @media (max-width: 575.98px) {
//   p {
//     font-size: 1.4rem;
//     line-height: 1.8rem;
//   }
//   small,
//   .small {
//     font-size: 1.2rem;
//     line-height: 1.6rem;
//   }
//   //
//   // Headings
//   //

//   h1,
//   .h1 {
//     @include font-size(2rem);
//     line-height: 2.2rem;
//   }
//   h2,
//   .h2 {
//     @include font-size(1.8rem);
//     line-height: 2.2rem;
//   }
//   h3,
//   .h3 {
//     @include font-size(1.5rem);
//     line-height: 1.9rem;
//   }
//   h4,
//   .h4 {
//     @include font-size(1.4rem);
//     line-height: 3.8rem;
//   }
//   h5,
//   .h5 {
//     @include font-size(20pxbelievo-meter-section);
//     line-height: 1.4rem;
//   }
//   h6,
//   .h6 {
//     @include font-size(0.9rem);
//     line-height: 1.2rem;
//   }
//   // Type display classes
//   .display-1 {
//     @include font-size(2.2rem);
//     line-height: 2.7rem;
//   }
//   .display-2 {
//     @include font-size(1.8rem);
//     line-height: 2.4rem;
//   }
//   .display-3 {
//     @include font-size(1.4rem);
//     line-height: 1.8rem;
//   }
//   .display-4 {
//     @include font-size(1.2rem);
//     line-height: 1.6rem;
//   }
//   .modal-title {
//     @include font-size(2.4rem);
//     line-height: 3rem;
//   }
// }
