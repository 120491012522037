.header {
  background-color: $light;
  padding: 3.3rem 0;
  color: $light;
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0;
  &-profile-pic {
    width: 4.2rem;
    height: 4.2rem;
    display: block;
    img {
      width: 100%;
      border-radius: 100%;
    }
  }
}
.home-page{
  .s-title{
    text-align: left;
  }
  .s-header{
    padding-bottom: 0.8rem;
  }
  .s-header .container-fluid{
    @media (max-width: 767.9px){
      padding-right: 2rem;

    }
  }
  .artist-name{
    margin-top: 0.7rem;
    line-height: 1rem;
  }
}
.send-reminder-header{
  .s-title{
    text-align: left;
  }
  .container-fluid{
    padding: 0 !important;
  }
}
.artist-header{
  .btn{
    top: -1rem !important;
    right: 0 !important;
    font-size: 1.2rem;
    .icon{
      display: none;
    }
  }
  &.artist-details-header{
    .btn{
      top: 0rem !important;
    }
  }
}
.notification-count {
  min-width: 0.7rem;
  min-height: 1.2rem;
  padding: 0 0.5rem;
  font-size: 6px;
  text-align: center;
  color: $light;
  line-height: 1.2rem;
  border-radius: 0.6rem;
  background-color: $danger;
  border-radius: 100%;
  position: absolute;
  right: 0;
  top: 0;
  font-family: 'TCCC-UnityText';
}
#dropdown-notification + .dropdown-menu-end {
  max-width: 62.4rem;
}
#dropdown-user + .dropdown-menu-end {
  min-width: 18.5rem;
  .dropdown-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon {
      font-size: 2rem;
    }
  }
}
.dropdown-menu-end {
  left: auto;
  right: 0;
}

.unique-code-wrapper {
  border: 2px solid $dark;
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px;

  input {
    font-size: 1.2rem;
    border: none;
    padding-right: 25px;
    background: transparent;
    color: $secondary;
    flex: 1;

    &:focus-visible {
      outline: none;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  .btn {
    border-radius: 10rem;
    margin-left: -40px;
  }
}
.google-icon-wrapper{
  img{
    padding: 10px;
    width: 47px;
    background-color: $light;
    border-radius: 50%;
  }
}
.via-wrapper{
  &>div{
    position: relative;
    font-size: 12px;
    &::after, &::before{
      content: '';
      height: 1.5px;
      width: calc(50% - 24px);
      border-bottom: 1.5px solid #CDCDCD;
      display: block;
      position: absolute;
      top: 9.5px;
    }
    &::before{
      left: 0;
    }
    &::after{
      right: 0;
    }
  }
}
.title-img-wrapper{
  display: flex;
  justify-content: start;
  img{
    width: 35px;
  }
}
@media (min-width: 576px) {
  .google-icon-wrapper{
    img{
      background-color: $grey;
    }
  }
}
@media (min-width: 768px) {
  .notification-count {
    min-width: 0.9rem;
    min-height: 1.4rem;
    border-radius: 0.7rem;
    font-size: 8px;
    line-height: 1.4rem;
  }
  .title-img-wrapper{
    justify-content: center;
    img{
      width: 60px;
    }
  }
  .send-reminder-header{
    .s-title{
      text-align: center;
    }
  }
  .home-page{
    .s-title{
      text-align: center;
    }
    .s-header{
      padding-bottom: 3rem;
    }
    .artist-name{
      font-size: 2.8rem;
      margin-top: 2rem;
      line-height: 3.2rem;
    }
  }
}
@media (min-width: 992px) {
  .header {
    padding: 1rem 0;
  }
}

.css-1p3m7a8-multiValue {
  display: none !important;
}
