.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto; /* Adjust as needed */
  }
  
  .loader-content {
    text-align: center;
  }
  
  .loading-text {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db; /* Change color as needed */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }