.accordion {
  &-item {
    padding: 1.6rem 0 1.5rem 0;
    border-bottom: 1px solid $grey-2;
    &:last-child{
      border-bottom: none;
    }
  }
  &-body {
    padding-top: 0.8rem;
    font-size: 1.2rem;
    line-height: 2.2rem;
  }
  &-header {
    position: relative;
    padding-right: 3rem;
  }
  &-button {
    border: none;
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 500;
    padding: 0;
    background: transparent;
    width: 100%;
    text-align: left;
    color: $dark;
    &:focus-visible {
      outline: unset;
    }
    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background: url('~/public/assets/img/icons/minus.svg') center center;
      width: 2.5rem;
      height: 2.5rem;
    }
    &.collapsed {
      &::after {
        background-image: url('~/public/assets/img/icons/plus.svg');
      }
    }

    &:focus {
      border: unset;
    }
  }
  @media (min-width: 767.98px) {
    margin-top: 4.4rem;
    &-item {
      padding: 3rem 0;
      &:first-child{
        border-top: 1px solid $grey-2;
      }
    }
    &-body {
      padding-top: 1rem;
      font-size: 1.6rem;
    }
    &-button {
      font-size: 2rem;
      line-height: 2.4rem;
    }
    &-content {
      font-size: 1.6rem;
    }
  }
}
