html,
body {
  font-size: 10px;
  font-weight: normal;
  // height: 100%;
  min-height: 100%;
}
#root {
  height: 100%;
  min-height: 100%;
}
img {
  max-width: 100%;
}
.grecaptcha-badge {
  bottom: 10rem !important;
  visibility: hidden;
}
ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.container,
.container-fluid {
  max-width: 1440px !important;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
}

.xy-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

main {
  padding: 10rem 0 9rem 0;
  min-height: calc(100vh - 21rem);
  &.reel-page{
    @media (max-width: 767.9px){
      padding: 0;
    }
  }
}

@media (min-width: 992px) {
  main {
    padding: 10.5rem 0 4.4rem 0;
    min-height: calc(100vh - 21rem);
  }
}

@media (min-width: 768px) {
  .container,
  .container-fluid {
    padding: 0 32px;
  }
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .col,
  [class*='col-'] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  main {
    padding-top: 15rem;
  }
}
