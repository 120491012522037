.card {
  border: unset;
  border-radius: 0.5rem;

  &-body {
    padding: 1.6rem 0.5rem;
    position: relative;
  }

  &-contest-horizontal {
    background: #fff;

    &-img-top {
      border-radius: 20px 0 20px 0;
    }
  }

  &-title {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-bottom: 0.6rem;
    font-weight: normal;
  }

  &-text {
    font-size: 0.8rem;
    line-height: 1.2rem;
    margin-bottom: 0.7rem;
    color: $secondary;
    font-weight: normal;
  }

  &-tiles {
    .btn {
      &-link {
        color: $dark;
        font-size: 1.2rem;
        display: inline-flex;
        align-items: center;
      }
    }

    .card {
      &-body {
        padding: 1rem 0 0 0;
      }

      &-title {
        font-size: 1rem;
        line-height: 1.4rem;
        margin-bottom: 0.3rem;
        font-weight: 500;
      }

      &-text {
        font-size: 1rem;
        line-height: 1.4rem;
        color: $secondary;
      }
    }
  }

  &-seeall {
    height: 100%;
    background-color: $light;
    border-radius: 0.7rem;
    overflow: hidden;

    .img {

      &-card,
      &-block {
        border-radius: 0;
      }
    }

    .card {
      &-body {
        padding: 1.6rem 1.2rem;
      }

      &-img {
        border-radius: 0;
      }

      &-title {
        font-size: 1.2rem;
        line-height: 1.4rem;
        color: #484848;
        font-weight: 500;
        margin-bottom: 0;

        // min-height:9rem;
        span {
          display: block;
          font-size: 1rem;
          line-height: 1.4rem;
          color: #484848;
          font-weight: 400;
          margin-top: 0.8rem;
        }
      }

      &-text {
        font-size: 1rem;
        line-height: 1.4rem;
        color: #484848;
      }

      &-new-badge {
        width: 4.6rem;
      }
    }

    .list-actions {
      margin: 1.2rem;
    }
  }

  &-horizontal {
    background-color: transparent;
    border-radius: unset;
  }

  &-lg {
    .card {
      &-title {
        font-size: 1.4rem;
        line-height: 1.8rem;
        margin-bottom: 0.8rem;
        font-weight: 500;
      }

      &-text {
        font-size: 1.2rem;
        line-height: 2rem;
        margin-bottom: 1rem;
        color: $dark;
      }
    }
  }

  &-top-img-wrapper {
    position: relative;
  }

  &-disabled {
    opacity: 0.5;
  }

  &-disabled-not-btn {

    .car-top-img-wrapper,
    .card-title,
    .card-text,
    .points-block {
      opacity: 0.5;
    }
  }
}

.reward-card {
  .card-text {
    min-height: 40px;
  }

  .card-img-top {
    max-height: 82px;
    height: 100%;
    object-fit: cover;
  }
}

.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 0 !important;
  margin-bottom: 10px;
}

.home-banner {
  .card-time-stamp {
    text-align: right;
    margin: 3px 16px 0px 0;
    display: flex;
    justify-content: end;
    align-items: center;

    span {
      &:first-child {
        font-size: 10px;
        font-weight: 600;
      }

      &:last-child {
        font-size: 10px;
        font-weight: 600;
        color: $light;
        background-color: $primary;
        border-radius: 20px 0 0 20px;
        padding: 4px 10px;
        margin-left: 6px;
      }
    }
  }
}

.no-notification {
  .icon-notifi {
    display: block;
    transform: rotate(10deg);
    font-size: 4rem;
    color: #838383;
  }
}

// Image blocks & cards
.img {
  &-block {
    position: relative;
    border-radius: 1rem;
    overflow: hidden;

    &::before {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    &-overlay {
      &::before {
        content: '';
        background-color: $dark;
        opacity: 0.7;
      }
    }

    &-gradient {
      &::before {
        content: '';
        background: rgb(0, 0, 0);
        background: linear-gradient(0deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0) 100%);
      }
    }

    &-sm {
      border-radius: 0.8rem;
    }
  }

  &-card {
    border-radius: 1rem;
    width: 100%;

    &-full-img {
      border-radius: 0;
    }

    &-sm {
      border-radius: 0.8rem;
    }
  }
}

// Social purpose icons
.icon {
  &:hover {
    color: inherit;
    text-decoration: none;
  }

  &-block {
    position: absolute;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    display: flex;
    justify-content: space-between;

    .icon {
      color: $light;
      font-size: 1rem;
      display: flex;
      align-items: center;
    }

    &-lg {
      .icon {
        &-play {
          font-size: 6.3rem;
        }
      }
    }

    &-top {
      bottom: unset;
      top: 1rem;
    }
  }

  &-num {
    font-family: 'TCCC-UnityText' !important;
    font-weight: normal !important;
    display: inline-block;
    padding-left: 0.2rem;
  }

  &-play {
    color: $light;
    font-size: 8rem;
    width: 4.3rem !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &-Lock {
    position: absolute;
    top: 1.1rem;
    right: 1.1rem;
    width: 1.7rem;
    color: $light;
    font-size: 1.7rem;

    &:hover {
      color: $light;
    }
  }

  &-back,
  &-close {
    font-size: 2rem;
    cursor: pointer;
  }

  &-notifi {
    font-size: 2.6rem;
    font-weight: 500;
    color: $dark;

    &:hover {
      color: $dark;
    }
  }
}

@media (min-width: 590px) {
  .card {
    &-seeall {
      .card {
        &-body {
          min-height: 9rem;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .card {
    &-body {
      padding: 3.6rem;
    }

    &-title {
      font-size: 2.4rem;
      line-height: 3.2rem;
      margin-bottom: 0.6rem;
    }

    &-text {
      font-size: 1.6rem;
      line-height: 1.8rem;
      margin-bottom: 2rem;
    }

    &-tiles {
      .btn {
        &-link {
          font-size: 1.6rem;
        }
      }

      .card {
        &-body {
          padding: 1.8rem 0 0 0;
        }

        &-title {
          font-size: 2rem;
          line-height: 1;
          margin-bottom: 0.6rem;
        }

        &-text {
          font-size: 1.6rem;
          line-height: 1;
        }
      }
    }

    &-horizontal {
      &-full-img {
        border-radius: 2rem;
      }
    }

    &-lg {
      .card {
        &-title {
          font-size: 2.8rem;
          line-height: 3.2rem;
          margin-bottom: 1.8rem;
        }

        &-text {
          font-size: 1.6rem;
          line-height: 2.6rem;
        }
      }
    }
    &-seeall {
      .card {
        &-body {
          padding: 1.4rem 1.8rem;
          // min-height: 9rem;
        }
        &-title {
          font-size: 1.4rem;
          line-height: 1.8rem;
          span {
            font-size: 1.2rem;
            line-height: 1.6rem;
          }
        }
        &-text {
          font-size: 1.6rem;
          line-height: 2rem;
        }
      }
      .list-actions {
        margin: 1.3rem 1.6rem;
      }
    }
  }

  // Image blocks & cards
  .img {
    &-block {
      border-radius: 2rem;

      &-sm {
        border-radius: 1.5rem;
      }
    }

    &-card {
      border-radius: 2rem;

      &-full-img {
        border-radius: 0;
      }

      &-sm {
        border-radius: 1.5rem;
      }
    }
  }

  // Social purpose icons
  .icon {
    &-block {
      left: 1.5rem;
      right: 1.5rem;
      bottom: 1.7rem;

      .icon {
        font-size: 1.5rem;
      }

      &-lg {
        left: 5rem;
        right: 5rem;
        bottom: 2rem;

        .icon {
          font-size: 3.7rem;

          &-num {
            font-size: 3rem;
            padding-left: 0.5rem;
          }

          &-play {
            font-size: 8rem;
          }
        }
      }

      &-top {
        bottom: unset;
        top: 2rem;
      }
    }

    &-play {
      font-size: 8rem;
      width: 8rem !important;
    }

    &-Lock {
      position: absolute;
      top: 1.1rem;
      right: 1.1rem;
      width: 3rem;
      font-size: 3rem;
    }

    &-num {
      font-size: 1.2rem;
    }

    &-like {
      font-size: 3rem;
    }
  }

  .reward-card {
    .card-text {
      min-height: 52px;
    }

    .card-img-top {
      max-height: 165px;
    }
  }

}

@media (min-width: 992px) {
  .card {
    &-seeall {
        .card {
            &-body {
                min-height: 7rem;
            }
        }
    }
}
  .img {
    &-card {
      &-full-img {
        border-radius: 2rem;
      }
    }
  }
}