.list {
  &-group {
    &-item {
      padding-left: 2rem;
      padding-right: 2rem;
      position: relative;
      &.active {
        &::before {
          content: '';
          width: 0.5rem;
          height: 0.5rem;
          background-color: $danger;
          border-radius: 100%;
          position: absolute;
          left: 2rem;
          top: 0.8rem;
        }
      }
      &:last-child {
        .list-group-media-wrapper {
          border: unset;
        }
      }
      .icon-close {
        font-size: 1.5rem;
        font-weight: bold;
        color: $secondary;
      }
      &-title {
        font-size: 1.2rem;
        line-height: 1.6rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
      }
      &-disc {
        font-size: 1rem;
        line-height: 1.4rem;
        font-weight: normal;
        padding-right: 4rem;
      }
      .text-muted {
        font-size: 0.8rem;
      }
      strong {
        font-size: 1rem;
        font-weight: 500;
      }
    }
    &-media {
      margin-right: 1.2rem;
      max-width: 5rem;
      max-height: 5rem;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 6px;
      }
      &-wrapper {
        display: flex;
        padding: 2rem 0;
        border-bottom: 1px solid $grey-2;
      }
      &-body {
        width: calc(100% - 6.3rem);
      }
    }
  }
  &-disc {
    list-style-type: disc;
  }
  &-social {
    gap: 1rem;
    .list {
      &-link {
        color: $dark;
        width: 3.4rem;
        height: 3.4rem;
        background-color: $light;
        border-radius: 100%;
        display: inline-block;
        text-align: center;
        line-height: 3.4rem;
        font-size: 2.5rem;
        &:hover {
          color: initial;
          text-decoration: none;
        }
      }
    }
  }
  &-actions {
    margin-top: 1.2rem;
    display: flex;
    gap: 1.2rem;
    .icon {
      margin-right: 0.6rem;
      font-size: 2rem;
    }
    .count {
      font-size: 1rem;
      line-height: 1.2;
      color: $dark;
      position: static;
      background-color: transparent;
    }
    a {
      color: $dark;
      display: flex;
      align-items: center;
    }
  }
  @media (min-width: 767.98px) {
    &-group {
      &-item {
        &-title {
          font-size: 1.6rem;
          line-height: 1.8rem;
          margin-bottom: 0.5rem;
        }
        &-disc {
          font-size: 1.2rem;
          line-height: 1.6rem;
        }
        .text-muted {
          font-size: 1.2rem;
          line-height: 1.6rem;
        }
        strong {
          font-size: 1.2rem;
          line-height: 1.6rem;
        }
      }
    }
    &-actions {
      margin-top: 1.5rem;
      gap: 1.6rem;
      .count {
        font-size: 1.4rem;
      }
    }
  }
}
