.capture-image {
  display: block;
  position: absolute;
  transform: translate(0%, 0%);
}

.modal {
  &-md {
    max-width: 696px;
  }

  &-body-success {
    margin-top: -2.4rem;
  }

  &-add-address {
    .modal-header {
      .modal-title {
        font-size: 1.6rem;
        margin-top: 3px;
      }

      .close {
        padding: 0;
      }
    }

    .footer-small-text {
      font-size: 0.4rem;
    }
  }

  &-body-refer-friend {
    max-width: 488px;
    margin-left: auto;
    margin-right: auto;

    .modal-description {
      font-size: 1rem;
      color: #484848;
    }

    .form-invert input[type] {
      background-color: #f1f1f1;
    }

    .btn {
      width: unset;
      margin-left: auto;
      margin-right: auto;
    }

    .mobile-input-wrapper .form-control {
      background-color: #f1f1f1;
    }
  }

  &-header {
    padding: 0;

    .close {
      padding: 2rem;
      margin: 0 0 0 auto;
      z-index: 1;

      img {
        width: 20px;
      }
    }
  }

  &-content {
    border-radius: 2rem;
  }

  &-content-unique-code {
    .modal-header {
      display: none;
    }
  }

  &-unique-code {
    .left-img-mobile {
      height: 112px;
    }

    .info-text {
      font-size: 0.8rem;
    }

    .info-points {
      margin-top: 1.6rem;
      position: static;
    }

    .info-card-pts {
      font-size: 0.8rem;
    }

    label {
      font-size: 0.8rem;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    .form-group {
      margin-bottom: 1rem;

      &>.text-danger {
        font-size: 0.8rem;
        left: 2px;
      }
    }

    .form-control {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #AEAEAE;
      background-image: url('~/public/assets/img/icons/unique-input-icon.svg') !important;
      background-repeat: no-repeat;
      background-position: right center;
      background-size: calc(0.5em + 1.7rem) calc(0.5em + 1.7rem);

      &.is-invalid {
        background-image: url('~/public/assets/img/icons/unique-input-icon.svg') !important;
      }
    }

    .form-footer {
      .btn {
        display: inline-block;
        padding: 0.6rem 2rem;
        width: unset;
        font-size: 0.8rem;
      }
    }

    input {
      padding-left: 2px;

      &::placeholder {
        font-size: 0.8rem;
      }
    }
  }

  &-code {
    text-align: center;
  }

  &-heading {
    font-size: 2.8rem;
    font-weight: 600;
  }

  &-description {
    font-size: 2.4rem;
  }

  &-notification {
    font-size: 1.6rem;
    color: $secondary;

    span {
      color: $danger;
      font-weight: 700;
    }
  }

  &-video-wrapper {
    .modal {
      &-body {
        height: 60vh;

        .react-player {
          width: auto !important;
          height: calc(100% - 35px) !important;
        }
      }
    }
  }

  &-filter {
    .s-title {
      font-size: 1.6rem;
      line-height: 1.6rem;
      padding-bottom: 1.2rem;
    }

    .btn-link {
      font-size: 1.2rem;
    }

    label {
      font-size: 1.2rem;
      color: #484848;
      font-weight: 600;
      margin-bottom: 1rem;
      text-transform: capitalize;
    }

    .select-btn-wrapper {
      .btn {
        font-size: 1.2rem;
      }
    }
  }

  &-reel-share, &-social-share {
    border-radius: 0;
    .close{
      background-color: #e9e9e9;
      margin-right: 10px;
      margin-top: 10px;
      padding: 5px !important;
      img{
        width: 10px !important;
        opacity: 0.6;
      }
    }
    .share-options {
      img {
        height: 40px;
        padding: 10px;
        border: 1px solid #d3cfcf;
        border-radius: 50%;
        width: 40px;
        object-fit: contain;
        margin-right: 15px;
        cursor: pointer;
        &:hover{
          border-color: #ff6fbe;
        }
      }
    }
    .copy-btn {
      margin-left: 10px;
      padding: 5px 10px;
      border: none;
      background-color: #fff;
      font-weight: 600;
      color: #ff6fbe;
    }
  }
  &-social-share{
    border-radius: 8px;
    .or-copy-link{
      border: 1px solid #d3cfcfc4;
      border-radius: 4px;
      
      input{
        color: #484848;
        font-weight: 600;
        border: none;
      }
    }
  }

  #modal-reel-share {
    align-items: end;
    margin: 0;
    min-height: 100%
  }

  @media (min-width:768.90px) {
    &-unique-code {
      label {
        font-size: 2.4rem;
        margin-bottom: 1.2rem;
      }

      .info-text {
        font-size: 1.6rem;
      }

      .info-points {
        margin-top: 1.6rem;
      }

      .info-card-pts {
        font-size: 1.3rem;
      }

      .form-group {
        margin-bottom: 2.4rem;

        &>.text-danger {
          font-size: 1.6rem;
        }
      }

      input {

        &::placeholder {
          font-size: 1.9rem;
        }
      }

      .form-footer {
        .btn {
          padding: 1.6rem 4rem;
          font-size: 1.6rem;
        }
      }
    }

    &-body-refer-friend {
      .modal-description {
        font-size: 2rem;
      }
    }

    &-add-address {
      .modal-header {
        .modal-title {
          font-size: 2rem;
          margin-top: 9px;
        }

        .close {
          padding: 0;
        }
      }

      .footer-small-text {
        font-size: 0.6rem;
      }
    }
    &-reel-share, &-social-share {
      border-radius: 10px 10px 0 0;
      p{
        font-size: 1.6rem;
        margin-bottom: 2.7rem;
      }
      .close{
        padding: 5px 8px !important;
        img{
          width: 12px !important;
        }
      }
      .share-options {
        img {
          height: 60px;
          padding: 15px;
          width: 60px;
          object-fit: contain;
          margin-right: 15px;
        }
      }
    }
    &-social-share{
      border-radius: 8px;
    }
    #modal-reel-share {
      
      align-items: center;
      margin: 0 auto;
      min-height: 100%
    }
  }

  @media (max-width:575.98px) {
    &-heading {
      font-size: 2rem;
    }

    &-description {
      font-size: 1.6rem;
    }

    &-notification {
      font-size: 1rem;

    }

    &-icon {
      width: 50px;
    }

    &-header {
      .close {
        padding: 1.6rem;

        img {
          width: 12px;
        }
      }
    }
  }
}