.popover {
  max-width: 62.4rem;
  padding: 0;
  &-header {
    background-color: unset;
    border: unset;
    padding: unset;
    padding-top: 2.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 0;
  }
  @media (min-width: 767.98px) {
    &-header {
      .title {
        font-size: 2.4rem;
        line-height: 2.8rem;
        font-weight: 500;
      }
    }
  }
}
