.btn {
  border: 2px solid transparent;
  font-size: 1.6rem;
  padding: 1.5rem 1.6rem;

  &-primary {
    border-color: $primary;
  }

  &-outline-primary {
    border-color: $primary;
  }

  &-outline-dark {
    border-color: $dark;
    color: $dark;

    &:hover {
      color: $light;
    }
  }

  &-underline {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  &-link {
    font-size: 1rem;
    font-weight: normal;

    &-lg {
      font-size: 1.4rem;
      line-height: 1;
    }
  }

  &-lg {
    padding: 2rem 1.6rem 2.2rem;
  }

  &-ex-lg {
    padding: 1.4rem 6rem;
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
      width: 2rem;
      height: 2rem;
      background-color: #ffffff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px;
      font-size: 11px;
      font-weight: 900;
    }
  }
}
.check-btn-profile{
  font-size: 1rem;
}
.upload-btn{
  .footer-pointbox{
    line-height: 1.7rem;
    .points-black{
      font-size: 0.9rem;
    }
    .info-card-sign{
      width: 1.3rem;
    }
    .points-num{
      line-height: 0.7;
      padding: 0 0.2rem 0 0.5rem;
    }
    &> span{
      font-size: 1rem;
      font-weight: 400;
    }
  }
  .title{
    font-size: 1.2rem;
  }
  .upload-icon{
    width: 1.6rem;
  }
}

@media (min-width: 768px) {
  .btn {
    padding: 1.5rem 3.2rem;
    font-size: 1.6rem;

    &-link {
      font-size: 1.6rem;
      font-weight: 500;
    }

    &-ex-lg {
      padding: 1.8rem 13.8rem;
    }
  }
  .check-btn-profile{
    font-size: 1.6rem;
  }
  .upload-btn{
    .footer-pointbox{
      &> span{
        font-size: 1.2rem;
      }
    }
    .title{
      font-size: 2rem;
    }
    .upload-icon{
      width: 2.9rem;
    }
    &>img{
      width: 1.2rem;
    }
  }
}