.points {
  &-block {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.2rem 0;
  }
  &-title {
    font-size: 0.8rem;
    line-height: 1.2rem;
    color: $secondary;
    padding-right: 0.5rem;
    font-weight: normal;
  }
  &-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $grey;
    @include border-radius(1.3rem);
  }
  &-num {
    font-size: 1rem;
    line-height: 1.1rem;
    color: $secondary;
    padding: 0 0.5rem 0 1rem;
    margin: 0;
    font-weight: normal;
  }
}

@media (min-width: 768px) {
  .points {
    &-block {
      margin: 2.8rem 0;
    }
    &-title {
      font-size: 1.6rem;
      line-height: 1.8rem;
      padding-right: 1rem;
    }
    &-box {
      @include border-radius(2.6rem);
    }
    &-num {
      font-size: 2rem;  
      line-height: 1;
      color: $secondary;
      padding: 0 1rem 0 1rem;
    }
    &-sign {
      width: 3.8rem;
    }
  }
}
