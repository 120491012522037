.ugc-page {
  .img {

    &-block,
    &-card {
      border-radius: 0;
    }
  }


  @media (min-width:767.98px) {
    .img {

      &-block,
      &-card {
        border-radius: 2rem;
      }
    }

    .tabs {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}
.ugc-upload{
  border: 1px solid #CDCDCD;
  border-radius: 1rem;
  .percentage-text{
    margin: 0;
    position: absolute;
    left: 0;
    top: 57px;
    text-align: center;
    width: 100%;
  }
}
.creator-table-wrapper {
  .s-header {
    max-width: 66.66%;
    margin: 0 auto;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .title-img-wrapper {
    justify-content: flex-start;
  }
}

.reels {

  // width: 818.822px;
  //   height: 1053.355px;
  //   position: relative;
  &-profile,
  &-icons {
    position: absolute;
    bottom: 10px;
  }

  &-back {
    color: #fff;
    font-size: 30px;
    position: absolute;
    top: 30px;
    left: 45px;
  }

  &-profile {

    font-size: 20px;
    color: #fff;
    margin-left: 15px;
  }

  &-icons {
    text-align: center;
    right: 0;

    .icon,
    img {
      width: 24px;
      height: 24px;
    }

    p {
      all: unset;
      color: #fff;

      &-num {
        font-size: 16px;
      }

      &-like,
      &-view {
        font-size: 30px;
      }
    }

  }

  &-view {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

}

.reels {

  &-profile,
  &-icons {
    position: absolute;
    bottom: 40px;
    z-index: 1;
  }

  &-back {
    color: #fff;
    font-size: 30px;
    position: absolute;
    top: 30px;
    left: 35px;
  }

  &-profile {

    font-size: 14px;
    color: #fff;
    margin-left: 15px;
  }

  &-icons {
    text-align: center;
    right: 20px;

    .icon,
    p {
      all: unset;
      color: #fff;

      &-num {
        font-size: 16px;
      }

      &-like,
      &-view {
        font-size: 28px;
      }
    }
    .icon-num {
      font-size: 12px;
    }

  }

  &-view {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  iframe {
    width: 100%;
    height: 100%;
    scroll-snap-align: start;

  }

}

.video {
  &-container {
    position: relative;
    height: 1050px;
    width: 100%;
    max-width: 580px;
    margin: 0 auto;
    border-radius: 20px;
    background-color: white;
    overflow: scroll;
    scroll-snap-type: y mandatory;
    scrollbar-width: none;

    &::after {
      content: "";
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 1.33%, #000 127.95%);
      height: 300px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-player {
    height: 100%;
    width: 100%;
  }

  &-card {
    position: relative;
    height: 100%;
    margin-bottom: 20px;
  }

}




.search-filter-wrapper {
  display: flex;
  border: 1px solid #CDCDCD;
  padding: 1.1rem;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;

  // &.search-focused {
  //   border-radius: 8px;
  // }

  .search {
    border: none;
    width: 100%;
    font-size: 1.2rem;

    &::placeholder {
      font-size: 1.2rem;
    }
  }

  // .search:focus {
  //   outline: none;
  //   padding: 4px;
  // }

  .search-icon {
    display: block;
  }

  .search:focus+.search-icon {
    display: none;
  }
}

@media (min-width:767.98px){
  .ugc-upload{
    border-radius: 1.5rem;
    p{
      font-size: 1.6rem;
    }
    img{
      width: 10rem;
    }
    .percentage-text{
      top: 102px;
    }
  }
}

@media (max-width:767.98px) {
  .reels {
    &-back {
      left: 15px;
    }
  }

  .video {
    &-container {
      border-radius: 0;
      &.reels{
        height: 100vh;
      }
    }
  }

  .ugc-page {
    background-color: $light;

    .search-filter-wrapper {
      border-radius: 100px;
    }

    .btn-filter {
      background-color: $light;
    }

    .tabs {
      padding-bottom: 0;
    }
  }

  .creator-table-wrapper {
    .s-header {
      max-width: 100%;
    }
  }
  

}



